import axios from 'axios'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
export default {

  data: () => ({
    person: {},
    isauth: false
  }),
  methods: {
    init() {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load();
      // Get the visitor identifier when you need it.

      (async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise
        const result = await fp.get()

        // This is the visitor identifier:
        const visitorId = result.visitorId
        if (visitorId) {
          if (localStorage.getItem('fs')) {
            if (visitorId != localStorage.getItem('fs')) {
              this.clearLocalStorate();
            } else {
              this.checkauth();
            }
          }
        }
      })()

    },
    checkauth() {
      if (localStorage.getItem('usertoken')) {
        axios({
            method: 'post',
            url: process.env.VUE_APP_BASE_URL + 'auth/me',
            headers: {
              Authorization: 'Bearer' + localStorage.getItem('usertoken')
            }
          })
          .then(response => {
            if (response) {
              this.person = response.data;
              this.isauth = true;
            }
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status == 500) {
                if (error.response.data.message == "The token has been blacklisted") {
                  this.clearLocalStorate();
                }
              }
            }
          })
      }
    },
    clearLocalStorate() {
      localStorage.removeItem("fs");
      localStorage.removeItem("usertoken");
      window.location.href = process.env.VUE_APP_PORTAL_URL + "/#/auth/login";
    },
  }
};