<template>
  <div class="about">
    <v-alert
      border="bottom"
      colored-border
      type="warning"
      elevation="2"
    >
        <h1>Oooops!</h1>
        <h2>Your account is not yet ready for this service.</h2>
    </v-alert>
  </div>
</template>
<script>

export default {
    name: 'none',
}

</script>