<template>
  <v-app v-if="isauth == 1">
    <navbar />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
  <v-app v-else-if="isauth == 2">
    <none />
  </v-app>
</template>

<script>
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import navbar from "@/components/navbar";
import none from "@/views/none";
export default {
  name: "App",
  components: {
    navbar,
    none,
  },
  data: () => ({
    isauth: 0,
  }),

  mounted() {
    this.initFingerprintJS();
  },
  methods: {
    initFingerprintJS() {
      // Initialize an agent at application startup.
      const fpPromise = FingerprintJS.load();
      // Get the visitor identifier when you need it.
      (async () => {
        // Get the visitor identifier when you need it.
        const fp = await fpPromise;
        const result = await fp.get();

        // This is the visitor identifier:
        const visitorId = result.visitorId;
        if (visitorId) {
          if (this.$route.params.id) {
            localStorage.removeItem("usertoken");
            localStorage.setItem("usertoken", this.$route.params.id);
          }

          if (this.$route.params.fs) {
            localStorage.removeItem("fs");
            localStorage.setItem("fs", visitorId);
          }

          if (localStorage.getItem("fs")) {
            if (visitorId != localStorage.getItem("fs")) {
              this.clearLocalStorate();
            } else {
              this.init();
            }
          } else {
            this.clearLocalStorate();
          }
        }
      })();
    },
    init() {
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "auth/me",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.getPerson();

            //alert(window.location.origin);
            //this.$router.next({ path: '/personal' })
            //this.$router.push({name: 'Personal'})
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              if (
                error.response.data.message == "The token has been blacklisted"
              ) {
                this.clearLocalStorate();
              }
            }
          }
        });
    },
    getPerson() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "pds/person",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            //
            if (response.data[0].length > 0) {
              this.isauth = 1;
            } else {
              this.isauth = 2;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    clearLocalStorate() {
      localStorage.removeItem("fs");
      localStorage.removeItem("usertoken");
      window.location.href = process.env.VUE_APP_PORTAL_URL + "/#/auth/login";
    },
  },
};
</script>
