<template>
    <nav>
        <v-navigation-drawer
            v-model="drawer"
            :clipped="$vuetify.breakpoint.lgAndUp"
            app
        >
            <v-list dense>
                <template v-for="(item,i) in items" v-bind="i">
                    <v-row
                        v-if="item.heading "
                        :key="item.heading"
                        align="center"
                        
                    >
                        <!--v-col cols="6">
                            <v-subheader v-if="item.heading">
                                {{ item.heading }} 
                            </v-subheader>
                        </v-col>
                        <v-col
                        cols="6"
                        class="text-center"
                        >
                            <a
                            href="#!"
                            class="body-2 black--text"
                            >EDIT</a>
                        </v-col-->
                    </v-row>
                    <v-list-group
                        v-else-if="item.children && item.dean == checkDean"
                        :key="item.text"
                        v-model="item.model"
                        :prepend-icon="item.model ? item.icon : item['icon-alt']"
                        append-icon=""
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item
                        v-for="(child, i) in item.children"
                        :key="i"
                        @click="goto(child.route)"
                        
                        
                        >
                            <v-list-item-action v-if="child.icon">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                        v-else
                        :key="item.text"
                        link router :to="item.route"
                        
                    >
                        <v-list-item-action v-if="item.dean == checkDean">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-action v-else-if="item.dean == 0">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        
                        <v-list-item-content v-if="item.dean == checkDean">
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>  
                        <v-list-item-content v-else-if="item.dean == 0">
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>  
                    </v-list-item>
                </template>
            </v-list>
        </v-navigation-drawer>
    
        <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="red darken-3"
        dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title
                style="width: 300px"
                class="ml-0 pl-4"
            >
                <span class="hidden-sm-and-down">Personal Data</span>
            </v-toolbar-title>
            <!--v-text-field
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                class="hidden-sm-and-down"
                
            ></v-text-field-->
            <v-spacer></v-spacer>
            <!--v-btn icon>
                <v-icon>mdi-apps</v-icon>
            </v-btn>
            <v-btn icon>
                <v-icon>mdi-bell</v-icon>
            </v-btn-->
            <v-btn
            icon
            large
            >
                <v-avatar
                size="32px"
                item
                >
                    <v-img
                    src="@/assets/download.png"
                    alt="Vuetify"
                ></v-img></v-avatar>
            </v-btn>
        </v-app-bar>
        <!--v-main>
            <v-container
            class="fill-height"
            fluid
            >
                <v-row
                align="center"
                justify="center"
                >
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-btn
                            :href="source"
                            icon
                            large
                            target="_blank"
                            v-on="on"
                            >
                                <v-icon large>mdi-code-tags</v-icon>
                            </v-btn>
                        </template>
                        <span>Source</span>
                    </v-tooltip>
                </v-row>
            </v-container>
        </v-main-->
        <!--v-btn
        bottom
        color="pink"
        dark
        fab
        fixed
        right
        @click="dialog = !dialog"
        >
            <v-icon>mdi-plus</v-icon>
        </v-btn-->
        <!--v-dialog
        v-model="dialog"
        width="800px"
        >
            <v-card>
                <v-card-title class="grey darken-2">
                Create contact
                </v-card-title>
                <v-container>
                    <v-row class="mx-2">
                        <v-col
                        class="align-center justify-space-between"
                        cols="12"
                        >
                            <v-row
                                align="center"
                                class="mr-0"
                            >
                                <v-avatar
                                size="40px"
                                class="mx-3"
                                >
                                <img
                                    src="//ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
                                    alt=""
                                >
                                </v-avatar>
                                <v-text-field
                                placeholder="Name"
                                ></v-text-field>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                prepend-icon="mdi-account-card-details-outline"
                                placeholder="Company"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                placeholder="Job title"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                prepend-icon="mdi-mail"
                                placeholder="Email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                type="tel"
                                prepend-icon="mdi-phone"
                                placeholder="(000) 000 - 0000"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                prepend-icon="mdi-text"
                                placeholder="Notes"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions>
                    <v-btn
                    text
                    color="primary"
                    >More</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    color="primary"
                    @click="dialog = false"
                    >Cancel</v-btn>
                    <v-btn
                    ext
                    @click="dialog = false"
                    >Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog-->
    </nav>
</template>

<script>
export default {
    name: 'navbar',
    data: () => ({
        usertoken: '',
        dialog: false,
        drawer: null,
        dean: 0,
        items: [
            { icon: 'mdi-contacts', text: 'Personal Info' , dean: 0, route: '/personal'},
            { icon: 'mdi-account-multiple', text: 'Family Background' , dean: 0, route: '/fam' },
            { icon: 'mdi-account-group', text: 'Name of Children' , dean: 0, route: '/chil' },
            { icon: 'mdi-school', text: 'Educational Background' , dean: 0, route: '/educ' },
            { icon: 'mdi-certificate', text: 'Civil Service Eligibility' , dean: 0, route: '/civil' },
            { icon: 'mdi-account-hard-hat', text: 'Work Experience' , dean: 0, route: '/we' },
            { icon: 'mdi-hand-heart', text: 'Voluntary Work or Involvement' ,  dean: 0,route: '/vwi' },
            { icon: 'mdi-developer-board', text: 'Learning and Development' , dean: 0, route: '/lad' },
            { icon: 'mdi-folder-information', text: 'Other Information' , dean: 0, route: '/oti' },
           
            /*
             { icon: 'mdi-chevron-up', 'icon-alt': 'mdi-chevron-down',text: 'Reports',  dean: 1,
             children: [
                { icon:'mdi-text-box-multiple-outline',  text: 'Faculty Seminars Attended', route:'seminarsattendedreport'},
               
              ],},

            {
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            text: 'Labels',
            model: true,
            children: [
                { icon: 'mdi-plus', text: 'Create label' },
            ],
            
            },
            
            {
            icon: 'mdi-chevron-up',
            'icon-alt': 'mdi-chevron-down',
            text: 'More',
            model: false,
            children: [
                { text: 'Import' },
                { text: 'Export' },
                { text: 'Print' },
                { text: 'Undo changes' },
                { text: 'Other contacts' },
            ],
            },
            { icon: 'mdi-cog', text: 'Settings' },
            { icon: 'mdi-message', text: 'Send feedback' },
            { icon: 'mdi-help-circle', text: 'Help' },
            { icon: 'mdi-cellphone-link', text: 'App downloads' },
            { icon: 'mdi-keyboard', text: 'Go to the old version' },
            */
        ],
    }),
    computed: {
      checkDean() {
        return this.dean;
      }
    },
    created(){
      this.isdean();
    },
    methods: {
      goto(url){
        window.open(process.env.VUE_APP_BASE_URL  +'pds/'+ url + '?token='+ localStorage.getItem('usertoken'));
      },
      isdean() {
        this.$axios({
          method: 'post',
          url: process.env.VUE_APP_BASE_URL + 'pds/isdean',
          headers: {
            Authorization: 'Bearer' + localStorage.getItem('usertoken')
          }
        })
        .then(res => {
          if(res){
              this.dean = res.data;
           
          }    
        })
        .catch(error => {
          if (error.response) {
            if(error.response.status == 500){
                //
            }
          }    
        })
      }
    }
};

</script>

