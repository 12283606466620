import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import api from '@/js/api';
import validation from '@/js/validation';

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;
Vue.prototype.$vldn = validation;

new Vue({
  Vuelidate,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
