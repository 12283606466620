import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/app/:id/:fs',
    name: 'auth',
    component: () => import('../views/auth.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal.vue')
  },
  {
    path: '/fam',
    name: 'Fam',
    component: () => import('../views/Fam.vue')
  },
  {
    path: '/chil',
    name: 'Chil',
    component: () => import('../views/Chil.vue')
  },
  {
    path: '/educ',
    name: 'Educ',
    component: () => import('../views/Educ.vue')
  },
  {
    path: '/civil',
    name: 'Civil',
    component: () => import('../views/Civil.vue')
  },
  {
    path: '/we',
    name: 'We',
    component: () => import('../views/We.vue')
  },
  {
    path: '/vwi',
    name: 'Vwi',
    component: () => import('../views/Vwi.vue')
  },
  {
    path: '/lad',
    name: 'Lad',
    component: () => import('../views/Lad.vue')
  },
  {
    path: '/oti',
    name: 'Oti',
    component: () => import('../views/Oti.vue')
  }
  
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
